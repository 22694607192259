import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import PostPreview from "../components/PostPreview"

export default function Template({ data, pageContext }) {
  const { project } = pageContext

  return (
    <Layout>
      <SEO title={project} />
      <h1>{project}</h1>
      {data.allMdx.nodes.map(node => (
        <PostPreview key={`/blog/${node.slug}`} node={node} />
      ))}
    </Layout>
  )
}

export const pageQuery = graphql`
  query($project: String!) {
    allMdx(
      filter: { frontmatter: { project: { eq: $project } } }
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: 1000
    ) {
      nodes {
        slug
        frontmatter {
          title
          date(formatString: "MMM D, YYYY")
          spoiler
        }
      }
    }
  }
`
